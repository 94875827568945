<template>
  <div>
    
    <div class="video-container">
      <video class="my-video" loop autoplay muted>
        <source src="@/assets/25.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>

  <v-snackbar v-model="snackbar">
    <v-icon class="primary--text pa-1">mdi-alert</v-icon>
    <span class="white--text">{{ snack }}</span>
    <template v-slot:action="{ attrs }">
      <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">Fermer</v-btn>
    </template>
  </v-snackbar>

  <v-dialog persistent v-model="dialogSubscribed"  max-width="500px">
    <v-card>
      <v-toolbar dark color="secondary white--text">
        <v-toolbar-title><v-icon>mdi-shield-check</v-icon> Finir son inscription</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialogSubscribed = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-2">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field @focus="presenteUser = false" v-model="name" label="Votre nom" name="Nom"
            placeholder="Ex: M. Dupond" :rules="nomRules" prepend-icon="mdi-account" class="required" required
            type="text"></v-text-field>
          <v-text-field id="v-step-2" v-model="tel" placeholder="Ex: 06 01 02 03 05" label="Votre Téléphone"
            name="Téléphone" @input="formatPhoneNumber" :rules="telRules" prepend-icon="mdi-phone"
            type="tel"></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success lighten-1" dark @click="validateSubscribe"><v-icon>mdi-check</v-icon> Continuer
          mon
          inscription</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-container class="mt-1">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="12" md="11">
        <v-card class="elevation-3">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title><b>Etape 1</b> Création de compte</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon large target="_blank" v-on="on">
                  <v-icon>mdi-account</v-icon>
                </v-btn>
              </template>
              <span class="white--text">Se connecter</span>
            </v-tooltip>
          </v-toolbar>

          <v-stepper v-model="e1" horizontal>
            <v-stepper-header>
              <v-stepper-step :complete="e1 > 1" step="1">Inscription du compte</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="2">Choix du plan</v-stepper-step>
              <v-stepper-step step="3">Importation des données</v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card-text>
                  <v-container>
                    <!-- <v-row>
                        <v-alert outlined type="success">Créez votre compte avec vos identifiants de connexion (e-mail
                          et
                          mot de
                          passe) afin de créer votre profil et d'importer votre premier inventaire.
                        </v-alert>
                      </v-row> -->
                    <v-row justify="center">

                      <v-col cols="12">
                        <v-form id="animations" v-model="valid" ref="form" :lazy-validation="false">

                          <v-row id="v-step-1" class="d-flex justify-center">
                            <v-radio-group v-model="type" row :rules="[v => !!v || 'Un profil doit être sélectionné']">
                              <v-radio label="Je travaille en viticulture 🍇" value="vigneron" class="pa-2"></v-radio>
                              <v-radio label="Je travaille en sylviculture 🌲" value="forestier" class="pa-2"></v-radio>
                            </v-radio-group>
                          </v-row>





                          <v-row v-if="(!name.length || !surname.length || presenteUser == false)">
                            <v-col cols="12" sm="6">
                              <v-text-field @focus="presenteUser = false" v-model="name" label="Votre nom"
                                @input="updateName" name="Nom" placeholder="Ex: M. Dupond" :rules="nomRules"
                                prepend-icon="mdi-account" class="required" required type="text"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <v-text-field @focus="presenteUser = false" @blur="presenteUser = true" v-model="surname"
                                label="Votre prénom" @input="updateSurname" name="Prénom" placeholder="Ex: Philippe"
                                :rules="surnameRules" prepend-icon="mdi-account" class="required" required
                                type="text"></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row class="ma-2" justify="center" v-if="presenteUser == true">
                            <v-btn elevation="2" raised color="primary" @click="presenteUser = false" rounded>
                              Bienvenue <strong class="pl-2">{{ name }} {{ surname }} </strong> 👋
                              <v-icon class="pa-2"> mdi-pencil </v-icon>
                            </v-btn>
                            <v-icon v-if="hover" color="teal" style="position: absolute; right: 0; bottom: 0;">
                              mdi-pencil
                            </v-icon>
                          </v-row>

                          <v-text-field v-model="nameCompagny" placeholder="Ex: SARL FORESTIER" label="Votre Entreprise"
                            name="Entreprise" :rules="nomCompagnyRules" prepend-icon="mdi-factory"
                            type="text"></v-text-field>

                          <v-text-field id="v-step-2" v-model="tel" placeholder="Ex: 06 01 02 03 05"
                            label="Votre Téléphone" name="Téléphone" @input="formatPhoneNumber" :rules="telRules"
                            prepend-icon="mdi-phone" type="tel"></v-text-field>

                          <v-text-field :loading="loading" v-model="email" placeholder="Ex: mon.email@gmail.com"
                            @input="verifyEmail" label="Votre E-mail"
                            hint="Votre e-mail sera votre identifiant de connexion" name="Email" :rules="emailRules"
                            :error="existEmail" :prepend-icon='existEmail ? "mdi-alert" : "mdi-email"' class="required"
                            type="email"></v-text-field>
                          <v-alert v-if="existEmail" type="error">Votre e-mail {{ this.email }}existe déjà sur notre
                            plateforme</v-alert>

                          <v-text-field id="password" :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="togglePassword" :rules="nameRules" placeholder="******"
                            hint="Choisissez un mot de passe robuste de 6 caractères!" v-model="password"
                            class="required" label="Votre mot de passe" name="password" prepend-icon="mdi-lock"
                            :type="showPassword ? 'text' : 'password'" @keyup.enter="login"></v-text-field>

                          <v-text-field id="password" :rules="nameRules" v-model="repassword"
                            placeholder="Resaisissez-le" class="required" label="Confirmation votre mot de passe"
                            name="password" :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="togglePassword" prepend-icon="mdi-shield-check"
                            :type="showPassword ? 'text' : 'password'" @keyup.enter="login"></v-text-field>



                          <v-col>
                            <!-- Texte avec un lien cliquable pour ouvrir la modal -->
                            <v-checkbox v-model="checkboxCGU"
                              :rules="[v => !!v || 'Vous devez accepter les CGU pour continuer.']">
                              <template v-slot:label>
                                <span>En utilisant ce service, vous acceptez nos </span>
                                <a class="pa-1" @click="dialogCGU = true">Conditions Générales d'Utilisation</a>.
                              </template>
                            </v-checkbox>

                            <v-checkbox v-model="checkboxNewsletter">
                              <template v-slot:label>
                                <span>J'accepte de recevoir la newsletter de Cartovia</span>
                              </template>
                            </v-checkbox>

                            <!-- Modal Dialog pour les CGU -->
                            <v-dialog v-model="dialogCGU" persistent max-width="800px">
                              <v-card>
                                <v-card-title class="headline">Conditions Générales de Vente et
                                  d'Utilisation</v-card-title>
                                <v-card-text>
                                  <p class="italic"><i> Il est important de lire attentivement et de comprendre les
                                      présentes conditions générales d’utilisation du site cartovia.fr.
                                      L’acceptation
                                      des
                                      présentes vous engage
                                      à en respecter les termes et les conditions exposés ci-après.
                                      La Société TAIWA est une société par actions simplifiée, au capital de 3 000
                                      €,
                                      inscrite au RCS de Lyon sous le numéro SIREN 848 091 799, dont le siège social
                                      est
                                      sis
                                      21 rue Commandant Fuzier, 69003 Lyon.</i>
                                    Les coordonnées de la société TAIWA sont les suivantes : 21 rue Commandant
                                    Fuzier,
                                    69003 Lyon</p>

                                  <h3>Article 1 : Définitions</h3>
                                  On désignera par la suite :
                                  <ul>
                                    <li>- Site ou service : le site cartovia.fr et l'ensemble de ses pages.</li>
                                    <li>- Produits : l'ensemble des services (immatériels) qu'il est possible
                                      d'acheter
                                      ou
                                      auxquels il est possible de souscrire sur le site.</li>
                                    <li>- Éditeur : La personne, morale ou physique, responsable de l'édition
                                      et du
                                      contenu
                                      du site.</li>
                                    <li>- Utilisateur' : L'internaute utilisant le site ou l'un de ses Produits.
                                    </li>
                                    <li>- Et 'Client' l'internaute effectuant un achat de service sur le site.
                                    </li>

                                  </ul>
                                  <h3> Article 2 : Champ d’application</h3>

                                  <p>Les présentes conditions générales d’utilisation s’appliquent sans restriction,
                                    ni
                                    réserve, à l’ensemble des services proposés par la société sur le site, sauf
                                    conditions spécifiques qui seraient applicables à certains services et feraient
                                    l’objet de conditions générales d’utilisation particulières.
                                    Ces conditions générales prévalent sur toutes autres conditions générales ou
                                    particulières non expressément agréées par la Société TAIWA. La souscription de
                                    services est réservée aux seuls utilisateurs ayant préalablement pris
                                    connaissance
                                    des
                                    présentes conditions générales d’utilisation dans leur intégralité et les ayant
                                    acceptées sans réserve.</p>
                                  L’utilisateur déclare et reconnait avoir lu les présentes conditions générales
                                  dans
                                  leur intégralité.
                                  Les présentes conditions générales d’utilisation prennent effet à compter de leur
                                  publication sur le site et demeurent en vigueur jusqu’à leur modification
                                  partielle
                                  ou
                                  totale par la société.
                                  Les conditions générales d’utilisation sont mises à jour de manière régulière et
                                  sont
                                  susceptibles de modifications, notamment par les extensions et améliorations
                                  possibles
                                  du fonctionnement du site. La société se réserve le droit de modifier
                                  unilatéralement
                                  les conditions générales d’utilisation à tout moment et sans préavis. Les
                                  conditions
                                  générales modifiées seront applicables immédiatement à tout utilisateur du site,
                                  quelle que soit la date d’adhésion.
                                  La société se réserve la possibilité d’adapter ou de modifier à tout moment le
                                  champ
                                  des prestations offertes par l’intermédiaire du site.


                                  Les conditions générales d’utilisation modifiées seront mises en ligne sur le site
                                  afin d’être portées à la connaissance des utilisateurs. Il appartient en
                                  conséquence
                                  à
                                  l’utilisateur de consulter et d’accepter les conditions générales d’utilisation du
                                  moment où il s’abonne notamment afin de s’assurer des dispositions applicables à
                                  cette
                                  date.
                                  Chaque utilisation des services proposés sur le site par les utilisateurs
                                  constitue
                                  l’acceptation de la dernière version des conditions générales d’utilisation
                                  publiées
                                  sur le site.
                                  Les présentes conditions générales s’appliquent pendant toute la durée
                                  d’utilisation
                                  de la plateforme et jusqu’à la clôture du compte utilisateur.


                                  <h3>Article 3 : Mentions imposées par la loi de confiance dans l’économie
                                    numérique
                                    et objet du site</h3>
                                  Le présent site est édité par TAIWA SAS. Les informations légales concernant
                                  l'hébergeur et l'éditeur du site, notamment les coordonnées et les éventuelles
                                  informations de capital et d'immatriculation, sont fournies dans les mentions
                                  légales
                                  du présent site. Les informations concernant la collecte et le traitement des
                                  données
                                  personnelles (politique et déclaration) sont fournies dans la charte de données
                                  personnelles du site. L'objet du présent site est déterminé comme « Site de
                                  solution
                                  de partage de fichier pour professionnel ». Le site est d’accès libre et gratuit à
                                  tout internaute. L’acquisition d’un bien ou d’un service, ou la création d’un
                                  espace
                                  membre, ou de manière plus générale la navigation sur le site suppose
                                  l’acceptation,
                                  par l’internaute, de l’intégralité des présentes conditions générales, qui
                                  reconnaît
                                  du même fait en avoir pris pleinement connaissance. Cette acceptation consistera,
                                  pour
                                  l’internaute, à cocher la case correspondant à la phrase d'acceptation des
                                  présentes
                                  conditions générales, ayant par exemple la mention « je reconnais avoir lu et
                                  accepté
                                  l’ensemble des conditions générales du site ». Le fait de cocher cette case sera
                                  réputé avoir la même valeur qu’une signature manuscrite de la part de
                                  l’internaute.
                                  L’internaute reconnaît la valeur de preuve des systèmes d’enregistrement
                                  automatique
                                  de l’éditeur du présent site et, sauf pour lui d’apporter une preuve contraire, il
                                  renonce à les contester en cas de litige. L’acceptation des présentes conditions
                                  générales suppose de la part des utilisateurs qu’ils jouissent de la capacité
                                  juridique nécessaire pour cela. Si l'utilisateur est mineur ou ne dispose pas de
                                  cette
                                  capacité juridique, il déclare avoir l’autorisation d’un tuteur, d’un curateur ou
                                  de
                                  son représentant légal.
                                  L'Éditeur met à la disposition du Client, sur son Site, une charte de
                                  confidentialité
                                  spécifiant l’ensemble des informations afférentes à l’utilisation des données à
                                  caractère personnel du Client collectées par l'Éditeur et aux droits dont le
                                  Client
                                  dispose vis-à-vis de ces données personnelles. La politique de confidentialité des
                                  données fait partie des CGUV. L'acceptation des présentes CGUV implique par
                                  conséquent
                                  l'acceptation de la politique de confidentialité des données.

                                  <h3>Article 4 : Caractéristiques des services proposés</h3>
                                  Les services et produits proposés sont ceux qui figurent dans le catalogue publié
                                  sur
                                  le site. Chaque produit ou service est accompagné d’un descriptif établi par
                                  l'éditeur
                                  du site. Les photographies des produits du catalogue reflètent une image fidèle
                                  des
                                  produits et services proposés mais ne sont pas contractuelles dans la mesure où
                                  elles
                                  ne peuvent pas assurer une parfaite similitude ou que le Service peut subir des
                                  évolutions.

                                  Le service d'assistance du présent site est accessible par courrier électronique à
                                  l’adresse suivante : pro@zenego.fr ou par courrier postal à l’adresse indiquée
                                  dans
                                  les mentions légales, auquel cas l’éditeur s’engage à apporter une réponse sous 7
                                  jours.

                                  <h3>Article 5 : Tarifs</h3>
                                  Les prix figurant dans le catalogue sont des prix entendus en Euros toutes taxes
                                  comprises (TTC), tenant compte de la TVA applicable au jour de la commande. TAIWA
                                  se
                                  réserve le droit de répercuter tout changement du taux de TVA sur le prix des
                                  produits
                                  ou des services. L'éditeur se réserve également le droit de modifier ses prix à
                                  tout
                                  moment. Néanmoins, le prix figurant au catalogue le jour de la commande sera le
                                  seul
                                  applicable à l’acheteur.

                                  <h3>Article 6 : Espace membre ou utilisateur</h3>

                                  L’utilisateur inscrit au site (membre) a la possibilité d’y accéder en se
                                  connectant
                                  grâce à ses identifiants (adresse e-mail définie lors de son inscription et mot de
                                  passe). L’utilisateur est entièrement responsable de la protection du mot de passe
                                  qu’il a choisi. Il est encouragé à utiliser des mots de passe complexes. En cas
                                  d’oubli de mot de passe, le membre a la possibilité d’en générer un nouveau. Ce
                                  mot
                                  de
                                  passe constitue la garantie de la confidentialité des informations contenues dans
                                  sa
                                  rubrique "mon compte" et l'utilisateur s'interdit donc de le transmettre ou de le
                                  communiquer à un tiers.
                                  L’utilisateur s’engage à fournir des données exactes et est tenu de remplir
                                  l’ensemble
                                  des champs obligatoires figurant dans le formulaire d’inscription.
                                  L’utilisateur personne physique doit être majeur et doit avoir la capacité de
                                  contracter. Le représentant d’une personne morale utilisatrice, certifie détenir
                                  l’intégralité des droits et pouvoirs nécessaires à la conclusion des présentes au
                                  nom
                                  de la personne morale qu’il représente.
                                  A défaut, l'éditeur du site ne pourra être tenu pour responsable des accès non
                                  autorisés au compte d'un utilisateur. La création d’un espace personnel est un
                                  préalable indispensable à toute souscription ou contribution du membre sur le
                                  présent
                                  site. A cette fin, le membre sera invité à fournir un certain nombre
                                  d’informations
                                  personnelles. Le membre s’engage à fournir des informations exactes. La collecte
                                  des
                                  données a pour objet la création d’un « compte membre ». Ce compte permet au
                                  membre
                                  de
                                  consulter les Produits souscrits sur le site et les abonnements dont il est
                                  titulaire.
                                  Si les données contenues dans la rubrique compte membre venaient à disparaître à
                                  la
                                  suite d’une panne technique ou d’un cas de force majeure, la responsabilité du
                                  site
                                  et
                                  de son éditeur ne pourrait être engagée, ces informations n’ayant aucune valeur
                                  probante mais uniquement un caractère informatif. Les pages relatives aux comptes
                                  membres sont librement imprimables par le titulaire du compte en question mais ne
                                  constituent nullement une preuve, elles n’ont qu’un caractère informatif destiné à
                                  assurer une gestion efficace des souscriptions et des éventuelles contributions du
                                  membre. L’éditeur se réserve le droit exclusif de supprimer le compte de tout
                                  membre
                                  qui aurait contrevenu aux présentes conditions générales (notamment, mais sans que
                                  cet
                                  exemple n’ait un quelconque caractère exhaustif, lorsque le membre aura fourni
                                  sciemment des informations erronées lors de son inscription et/ou de la
                                  constitution
                                  de son espace personnel) ou encore tout compte inactif depuis au moins une année.
                                  Ladite suppression ne sera pas susceptible de constituer un dommage pour le membre
                                  exclu qui ne pourra prétendre à aucune indemnité de ce fait. Cette exclusion n’est
                                  pas
                                  exclusive de la possibilité, pour l’Éditeur, d’entreprendre des poursuites d’ordre
                                  judiciaire à l’encontre du membre, lorsque les faits l’auront justifiées.
                                  En cas de perte ou vol de ses identifiants, ou d’une suspicion d’utilisation
                                  frauduleuse de son compte, l’utilisateur est tenu d’en informer la société TAIWA
                                  en
                                  envoyant un courriel à l’adresse suivante contact@taiwa.fr et en précisant son nom
                                  d’utilisateur et le confirmer par lettre recommandée avec avis de réception à
                                  l’adresse du siège social en tête des présentes.

                                  <h3> Article 7 : Exonération de la responsabilité de l’éditeur dans le cadre de
                                    l’exécution du présent contrat</h3>
                                  En cas d’impossibilité d’accès au site, en raison de problèmes techniques ou de
                                  toutes
                                  natures, l'Utilisateur ne pourra se prévaloir d’un dommage et ne pourra prétendre
                                  à
                                  aucune indemnité. L’indisponibilité, même prolongée et sans aucune durée
                                  limitative,
                                  d’un ou plusieurs produits, ne peut être constitutive d’un préjudice pour les
                                  internautes et ne peut aucunement donner lieu à l’octroi de dommages et intérêts
                                  de
                                  la
                                  part du site ou de son éditeur. Les photographies et visuels des produits
                                  présentés
                                  sur le site n’ont aucun caractère contractuel, la responsabilité de l’éditeur du
                                  présent site ne saurait donc être engagée si les caractéristiques des objets
                                  diffèrent
                                  des visuels présents sur le site ou si ces derniers sont erronés ou incomplets.
                                  Les
                                  liens hypertextes présents sur le présent site peuvent renvoyer sur d’autres sites
                                  internet et la responsabilité de l’éditeur du présent site ne saurait être engagée
                                  si
                                  le contenu de ces sites contrevient aux législations en vigueur. De même la
                                  responsabilité de l’éditeur du présent site ne saurait être engagée si la visite,
                                  par
                                  l’internaute, de l’un de ces sites, lui causait un préjudice.
                                  <h3>Article 8 : Droits de propriété intellectuelle relatifs aux éléments publiés
                                    sur
                                    le
                                    présent site</h3>
                                  Tous les éléments du présent site appartiennent à l'éditeur ou à un tiers
                                  mandataire,
                                  ou sont utilisés par l'éditeur sur le site avec l'autorisation de leur
                                  propriétaire.
                                  Toute copie des logos, contenus textuels, pictographiques ou vidéos, sans que
                                  cette
                                  énumération ne soit limitative, est rigoureusement interdite et s’apparente à de
                                  la
                                  contrefaçon. Tout membre qui se rendrait coupable de contrefaçon serait
                                  susceptible
                                  de
                                  voir son compte supprimé sans préavis ni indemnité et sans que cette suppression
                                  ne
                                  puisse lui être constitutive d’un dommage, sans réserve d’éventuelles poursuites
                                  judiciaires ultérieures à son encontre, à l’initiative de l’éditeur du présent
                                  site
                                  ou
                                  de son mandataire.
                                  <h3>Article 9 : Marques</h3>
                                  Les marques et logos contenus dans le site sont déposés par TAIWA, ou
                                  éventuellement
                                  par un de ses partenaires. Toute personne procédant à leurs représentations,
                                  reproductions, imbrications, diffusions et rediffusions encourt les sanctions
                                  prévues
                                  aux articles L.713-2 et suivants du Code de la propriété intellectuelle.

                                  <h3> Article 10 : Limitation de responsabilité</h3>
                                  L'éditeur du site, notamment dans le processus de vente en ligne, n’est tenu que
                                  par
                                  une obligation de moyens ; sa responsabilité ne pourra être engagée pour un
                                  dommage
                                  résultant de l’utilisation du réseau Internet tel que perte de données, intrusion,
                                  virus, rupture du Service ou autres.
                                  La société TAIWA s’engage à exécuter, ou à faire exécuter par ses partenaires, ses
                                  obligations, de manière loyale et diligente, conformément aux termes des présentes
                                  conditions générales d’utilisation.
                                  TAIWA ne saurait être tenu pour responsable de l'inexécution du contrat conclu,
                                  due
                                  à
                                  la survenance d'un événement de force majeure et notamment en cas de grève totale
                                  ou
                                  partielle de services externes ou de catastrophes causées par des inondations ou
                                  des
                                  incendies. Concernant les produits achetés, l'éditeur n'encourra aucune
                                  responsabilité
                                  pour tous dommages indirects du fait des présentes, perte d'exploitation, perte de
                                  profit, dommages ou frais, qui pourraient survenir. Le choix et la souscription à
                                  un
                                  Produit sont placés sous l'unique responsabilité de l'utilisateur. L'utilisateur
                                  admet
                                  expressément utiliser le site à ses propres risques et sous sa responsabilité
                                  exclusive. Le site fournit à l'utilisateur des informations à titre indicatif,
                                  avec
                                  des imperfections, erreurs, omissions, inexactitudes et autres ambivalences
                                  susceptibles d'exister. En tout état de cause, l'éditeur ne pourra en aucun cas
                                  être
                                  tenu responsable :- de tout dommage direct ou indirect, notamment en ce qui
                                  concerne
                                  les pertes de profits, le manque à gagner, les pertes de clientèle, de données
                                  pouvant
                                  entre autres résulter de l'utilisation du site, ou au contraire de l'impossibilité
                                  de
                                  son utilisation ;- d'un dysfonctionnement, d'une indisponibilité d'accès, d'une
                                  mauvaise utilisation, d'une mauvaise configuration de l'ordinateur de
                                  l'utilisateur,
                                  ou encore de l'emploi d'un navigateur peu usité par l'utilisateur ;- du contenu
                                  des
                                  publicités et autres liens ou sources externes accessibles par l'utilisateur à
                                  partir
                                  du site

                                  <h3> Article 11 : Accès au site</h3>
                                  Compte tenu des spécificités d’internet, la société TAIWA n’est aucunement
                                  responsable
                                  du fonctionnement continu du site et n’est tenue que d’une obligation de moyens.
                                  La
                                  responsabilité de la société TAIWA ne saurait être engagée en cas d’impossibilité
                                  temporaire d’accéder au site ou à l’un des services.
                                  Ainsi, la Société TAIWA ne saurait être tenue responsable des divers
                                  dysfonctionnements d’internet ou des fournisseurs d’accès des utilisateurs, ou de
                                  la
                                  mauvaise utilisation du site par ces derniers, qui causerait des difficultés voire
                                  une
                                  impossibilité pour accéder au site ou aux divers services.
                                  Une mauvaise utilisation du site par les utilisateurs ne saurait engager la
                                  responsabilité de la société TAIWA, sauf exceptions prévues par la loi ou les
                                  règlements en vigueur.
                                  La responsabilité de l'éditeur du site ne peut être engagée en raison d'une
                                  indisponibilité technique de la connexion, qu'elle soit due notamment à un cas de
                                  force majeure, à une maintenance, à une mise à jour, à une modification du site, à
                                  une
                                  intervention de l'hébergeur, à une grève interne ou externe, à une panne de
                                  réseau,
                                  à
                                  une coupure d'alimentation électrique, ou encore à une mauvaise configuration ou
                                  utilisation de l'ordinateur de l'utilisateur.

                                  <h3>Article 12 : Force Majeure</h3>
                                  La Société TAIWA ou ses partenaires, ne pourra être tenue responsable de tout
                                  incident
                                  et perturbation sur le réseau internet rendant l’accès au site indisponible, en
                                  cas
                                  de
                                  fait d’un tiers ou en cas de force majeure telle que définie par la législation et
                                  jurisprudence française.
                                  A ce titre, la force majeure s’entend de tout événement extérieur, imprévisible et
                                  irrésistible au sens de l’article 1218 du code civil.
                                  Toutefois, en cas de survenance d’un cas de force majeure, la société TAIWA, ses
                                  partenaires et les utilisateurs ne sont exonérés de l’exécution de leurs
                                  obligations
                                  respectives que pendant la durée de l’événement en cause.
                                  La responsabilité de la société TAIWA ne pourra en aucun cas être engagée du fait
                                  d’un
                                  tiers endommageant le site ou préjudiciant au bon fonctionnement de celui-ci, ni
                                  même
                                  en cas de défaillance de l’hébergeur partenaire du site. Plus généralement, elle
                                  ne
                                  sera pas non plus responsable des dysfonctionnements de ses partenaires et
                                  notamment
                                  du site de paiement.
                                  La société TAIWA ne saurait à cet égard être tenue pour responsable de tout
                                  dommage
                                  résultant de la perte, de l’altération ou de toute autre utilisation frauduleuse
                                  de
                                  données, de la transmission accidentelle de virus ou autres éléments nuisibles, de
                                  l’attitude ou du comportement de tiers.
                                  Elle n’encourt aucune responsabilité du fait de l’impossibilité d’accéder au site,
                                  d’un mauvais usage de celui-ci, de la saturation du réseau internet ou d’éventuels
                                  dysfonctionnements sur les terminaux mobiles, électroniques, des équipements
                                  utilisateurs.

                                  <h3>Article 13 : Fermeture de compte</h3>
                                  Chaque membre du site est libre de fermer son compte. Pour ceci, le membre doit
                                  adresser un e-mail au site indiquant qu’il souhaite supprimer son compte. La
                                  récupération de ses données par le membre est possible aux conditions précisées
                                  dans
                                  la section des présentes conditions générales concernant la restitution des
                                  données.


                                  <h3>Article 14 : Droit applicable et médiation</h3>
                                  Les présentes conditions générales sont soumises à l’application du droit
                                  Français.
                                  Elles peuvent être modifiées à tout moment par l’éditeur du site ou son
                                  mandataire.
                                  Les conditions générales applicables à l’utilisateur sont celles en vigueur au
                                  jour
                                  de
                                  sa souscription à un service sur le présent site. L’éditeur du site s’engage à
                                  conserver les anciennes versions des présentes conditions générales en cas
                                  d'évolution
                                  et à les faire parvenir à tout Utilisateur qui en ferait la demande.
                                  En cas de litige, vous pouvez adresser une réclamation écrite à notre service
                                  client,
                                  par courriel ou voie postale à l’adresse mentionnée en tête des présentes.
                                  En cas de difficultés ou de différend entre les parties à l’occasion de
                                  l’interprétation, de l’exécution ou de la cessation des présentes conditions
                                  générales
                                  les parties s’engagent à rechercher une solution amiable avant tout procès, quel
                                  que
                                  soit le mode de règlement choisi, les parties devront en être d’accord et
                                  notamment
                                  la
                                  médiation.
                                  La solution qui émergerait dans le cadre d’un règlement amiable du différend ne
                                  s’impose pas aux parties qui restent libres à tout moment de sortir du processus
                                  de
                                  règlement amiable choisi par elles.
                                  Étant précisé également que tout litige avec un utilisateur agissant en qualité de
                                  commerçant et né à l’occasion de l’interprétation, de l’exécution ou de la rupture
                                  des
                                  présentes conditions générales est de la compétence du Tribunal de Commerce de
                                  Lyon.
                                  Sauf dispositions d’ordre public, tous litiges qui pourraient survenir dans le
                                  cadre
                                  de l’exécution des présentes conditions générales pourront avant toute action
                                  judiciaire être soumis à l’appréciation de l’éditeur du site en vue d’un règlement
                                  amiable. Il est expressément rappelé que les demandes de règlement amiable ne
                                  suspendent pas les délais ouverts pour intenter les actions judiciaires.

                                  <h3>Article 15 : Utilisation de Cookies</h3>
                                  Un « Cookie » permet l'identification de l'utilisateur d'un site, la
                                  personnalisation
                                  de sa consultation du site et l'accélération de l’affichage du site grâce à
                                  l'enregistrement d'un fichier de données sur son ordinateur. Le site est
                                  susceptible
                                  d'utiliser des « Cookies » principalement pour 1) obtenir des statistiques de
                                  navigation afin d'améliorer l'expérience de l'Utilisateur, et 2) permettre l'accès
                                  à
                                  un compte de membre et à du contenu qui n'est pas accessible sans connexion.
                                  L'Utilisateur reconnaît être informé de cette pratique et autorise l'éditeur du
                                  site
                                  à
                                  y recourir. L'éditeur s'engage à ne jamais communiquer le contenu de ces « Cookies
                                  »
                                  à
                                  des tierces personnes, sauf en cas de réquisition légale. L'Utilisateur peut
                                  refuser
                                  l'enregistrement de « Cookies » ou configurer son navigateur pour être prévenu
                                  préalablement à l'acception les « Cookies ». Pour ce faire, l'Utilisateur
                                  procédera
                                  au
                                  paramétrage de son navigateur :
                                  - Pour Internet Explorer :
                                  http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies
                                  - Pour Safari : https://support.apple.com/fr-fr/ht1677
                                  - Pour Google Chrome :
                                  https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en&safe=on
                                  - Pour Firefox : https://support.mozilla.org/fr/kb/activer-desactiver-cookies
                                  - Pour Opera : http://help.opera.com/Windows/10.20/fr/cookies.html

                                  <h3>Article 16 : Modalité de souscription au service et descriptif du processus
                                    d’achat</h3>

                                  On définira ci-dessous comme « Panier » l’objet immatériel (par exemple une page
                                  ou
                                  partie de page du site) regroupant l’ensemble des Produits sélectionnés par
                                  l’utilisateur en vue d’un achat ou abonnement. Dès lors que l’internaute estimera
                                  avoir sélectionné et ajouté à son panier tous les produits qu’il souhaite acheter,
                                  il
                                  aura la possibilité, pour valider sa commande, d’accéder à une page récapitulative
                                  sur
                                  laquelle lui seront communiqués les caractéristiques (notamment la volumétrie et
                                  d'éventuelles options) des produits commandés, ainsi que leur prix unitaire, ou le
                                  prix de l'abonnement selon la nature de la tarification du service. S’il souhaite
                                  valider sa commande, l’utilisateur devra cocher la case relative à la ratification
                                  des
                                  présentes conditions générales de vente et cliquer sur le bouton de validation.
                                  L’utilisateur sera alors amené à remplir les champs d'un formulaire de commande en
                                  renseignant un certain nombre de données personnelles le concernant, nécessaires
                                  au
                                  bon déroulement de la commande. Dès lors que l’utilisateur aura validé ce
                                  formulaire
                                  de commande, il sera redirigé sur l’interface de paiement en ligne sur laquelle il
                                  pourra effectuer son paiement avec les moyens de paiement précisés dans la section
                                  dédiée des présentes conditions générales, ou bien aura accès au bon de commande
                                  nécessaire pour adresser un chèque, le cas échéant. Après quelques instants
                                  l’utilisateur se verra adresser un courrier électronique de confirmation de la
                                  commande, lui rappelant le contenu de la commande et le prix de celle-ci.

                                  <h3>Article 17 : Informations relatives au paiement</h3>
                                  L’internaute peut passer commande sur le présent site et peut effectuer son
                                  règlement
                                  par carte bancaire. Les paiements par carte bancaire se font au moyen de
                                  transactions
                                  sécurisées fournies par un prestataire de plateforme de paiement en ligne. Le
                                  paiement
                                  est effectué directement entre les mains de la banque ou du prestataire de
                                  paiement
                                  recevant le paiement du Client. En cas de paiement par chèque ou virement
                                  bancaire,
                                  les délais de mise à disposition définis à l’article ci-dessous ne commencent à
                                  courir
                                  qu’à compter de la date de réception effective du paiement par le vendeur, ce
                                  dernier
                                  pouvant en apporter la preuve par tous moyens.

                                  <h3>Article 18 : Mise à disposition</h3>
                                  La mise à disposition du service (autrement appelée sa livraison) est immédiate,
                                  ou
                                  nécessite un délai court. Ce délai ne pourra cependant excéder 7 jours. Toute
                                  réclamation non effectuée dans les règles définies dans la section dédiée des
                                  présentes conditions générales et dans les délais impartis ne pourra être prise en
                                  compte et dégagera la société TAIWA de toute responsabilité vis à vis de
                                  l’acheteur.
                                  A
                                  réception d'une réclamation valide, la société TAIWA communiquera par email, par
                                  fax
                                  ou par téléphone à l’acheteur les modalités de remboursement ou de modification de
                                  la
                                  commande.

                                  <h3>Article 19 : Résiliation et droit de rétractation</h3>
                                  Le présent article s’applique uniquement aux Acheteurs consommateurs /
                                  non-professionnels et Acheteurs professionnels (répondant aux conditions de
                                  l’article
                                  L.221-3 du Code de la consommation).
                                  Art. 19.1 – Principe du droit de rétractation
                                  Aux termes de l’article L. 221-18 du Code de la consommation, l’Acheteur
                                  consommateur
                                  dispose d'un délai de quatorze (14) jours calendaires à compter du lendemain de la
                                  livraison ou du retrait des Produits commandés, pour exercer son droit de
                                  rétractation
                                  auprès de la société TAIWA, sans avoir à justifier de motifs, ni à payer de
                                  pénalités,
                                  à fin d’échange ou de remboursement.
                                  Si le délai de quatorze (14) jours ouvrés expire un samedi, un dimanche ou un jour
                                  férié ou chômé, il est prorogé jusqu'au premier jour ouvrable suivant.
                                  Si l’Acheteur use de son droit de rétractation, le retour des Produits doit
                                  s'effectuer dans leur emballage d'origine, en état de revente à neuf, accompagnés
                                  de
                                  tous les accessoires et notices éventuels en parfait état de revente, avec la
                                  facture
                                  originale d’achat à laquelle les Produits se rapportent et le numéro de retour
                                  fourni
                                  par le Service client de la société TAIWA.
                                  Tout Produit retourné endommagé, sali ou incomplet pourrait mettre en cause la
                                  responsabilité de l’Acheteur par la société TAIWA dans les conditions de l’article
                                  L.
                                  221-23 du Code de la consommation.
                                  En cas d'exercice du droit de rétractation dans le délai susvisé, seul le prix du
                                  ou
                                  des produits achetés ,dans un état propre a la revente, sera remboursé; dans tous
                                  les
                                  cas, les frais de transport restent à la charge exclusive de l’Acheteur.
                                  En l’absence de réception du Produit ou d’une preuve d’expédition de celui-ci dans
                                  ce
                                  délai, la Société TAIWA se réserve le droit de reporter le remboursement de
                                  l’Acheteur
                                  jusqu’au jour de la réception dudit Produit.
                                  Le remboursement pourra être effectué dans un délai maximum de quatorze (14) jours
                                  à
                                  compter de la réception, par la société TAIWA, des Produits retournés par
                                  l’Acheteur
                                  dans les conditions prévues au présent article.
                                  La Société TAIWA procédera au remboursement de l’Acheteur via le même moyen de
                                  paiement que celui utilisé au jour de la commande, sauf dans le cas où le moyen de
                                  paiement utilisé ne le permettrait pas.
                                  Art. 19.2 – Exceptions au droit de rétractation
                                  Conformément à l’article L. 221-28 du Code de la consommation, les dispositions de
                                  l’article 11.1. ne s’appliquent notamment pas :
                                  Aux Produits confectionnés selon les spécifications de l’Acheteur ou nettement
                                  personnalisés ;
                                  Aux Produits susceptibles de se détériorer ou de se périmer rapidement ;
                                  Aux Produits qui, après avoir été livrés et de par leur nature, sont mélangés de
                                  manière indissociable avec d'autres produits ;
                                  Aux Produits qui ont été descellés par l’Acheteur après la livraison et qui ne
                                  peuvent
                                  être renvoyés pour des raisons d’hygiènes ou de protection de la santé ;

                                  <h3>Article 20 : Archivage</h3>
                                  TAIWA archivera les bons de commandes et les factures sur un support fiable et
                                  durable
                                  constituant une copie fidèle. Les registres informatisés seront considérés par les
                                  parties comme preuve des communications, commandes, paiements et transactions
                                  intervenus entre les parties

                                  <h3> Article 21 : Conditions d’accès à la solution</h3>
                                  Le présent site met à disposition de l'Utilisateur une solution sur son serveur,
                                  accessible par le biais du réseau internet. Les différents programmes de solutions
                                  proposés et les conditions d’abonnement correspondants sont présentés sur les
                                  pages
                                  d'offres du site. Selon l’offre choisie, le site communiquera à l'utilisateur la
                                  durée
                                  de validité de son abonnement. Le site réalise la sauvegarde et la sécurisation
                                  des
                                  données. Le site concède à l'utilisateur un droit personnel, non exclusif, non
                                  cessible et non transférable d’utilisation des solutions, pendant toute la durée
                                  du
                                  contrat et pour le monde entier. L'utilisateur ne peut utiliser les services
                                  applicatifs et les solutions que conformément à ses besoins et à leur
                                  documentation.
                                  En particulier, la licence relative aux solutions n’est concédée que dans le seul
                                  et
                                  unique but de permettre à l'utilisateur l’utilisation des services, à l’exclusion
                                  de
                                  toute autre finalité. Le droit d’utilisation s’entend du droit de représenter et
                                  de
                                  mettre en œuvre les services applicatifs conformément à leur destination, en mode
                                  SaaS
                                  ("Software as a Service", logiciel en tant que service) via une connexion à un
                                  réseau
                                  de communications électroniques. L'utilisateur ne pourra en aucun cas mettre les
                                  solutions à disposition d’un tiers, et s’interdit strictement toute autre
                                  utilisation,
                                  en particulier toute adaptation, modification, traduction, arrangement, diffusion,
                                  décompilation, sans que cette liste soit limitative. Si le site met en place un
                                  engagement minimum de souscription pour certaines de ses offres, celui-ci sera
                                  stipulé
                                  clairement et distinctement sur la page de l'offre et lors du processus de
                                  souscription.



                                  <h3>Article 22 : Paiement</h3>
                                  Les différents programmes de solutions proposés et leurs prix d’abonnement
                                  correspondants sont présentés sur les pages d'offres ou de tarifs du site.
                                  Le site précise sur ces pages si la facturation est à la période (à la journée, au
                                  mois, à l’année, toute période commencée étant due, sauf indication contraire, par
                                  le
                                  client), ou bien fixée selon un niveau d’utilisation des ressources (toute unité
                                  de
                                  ressource entamée étant due, sauf indication contraire, par le client), ou tout
                                  autre
                                  voie d’accès, précisé sur cette dite page.
                                  Sauf indication contraire lors de la souscription au service, le mode de
                                  facturation
                                  qui s'applique est le suivant : abonnement. Le mode de facturation est de même
                                  précisé
                                  sur les pages d'offres ou de tarifs du site, ou à défaut dans la section
                                  concernant
                                  les conditions d’accès à la solution de ces conditions générales.
                                  En cas de prélèvement automatique, le site précisera au client dans l’interface de
                                  paiement la date du prélèvement, et son montant, ainsi que la fréquence, celle-ci
                                  pouvant dépendre de chaque client.

                                  <h3> Article 23 : Protection des données personnelles</h3>
                                  L’utilisation du site et de ses services implique le traitement des données
                                  personnelles des visiteurs et utilisateurs par la société TAIWA.
                                  Certaines données peuvent être collectées par l’intermédiaire des cookies et
                                  autres
                                  moyens similaires présents sur internet. Chaque visiteur et utilisateur déclare
                                  qu’il
                                  connait parfaitement la présence et la gestion de ces cookies et technologies
                                  assimilées.

                                  Les informations recueillies sur l’utilisateur font l’objet d’un traitement
                                  informatique réalisé par la société TAIWA et sont indispensables au bon
                                  fonctionnement
                                  du site et de l’inscription des utilisateurs. Ces informations et données
                                  personnelles
                                  sont également conservées à des fins de sécurité, afin de respecter les
                                  obligations
                                  légales et réglementaires. Elles seront conservées aussi longtemps que nécessaire
                                  pour
                                  le fonctionnement des comptes utilisateurs.
                                  L’accès aux données personnelles sera strictement limité aux employés du
                                  responsable
                                  de traitement, habilités à les traiter en raison de leurs fonctions. Les
                                  informations
                                  recueillies pourront éventuellement être communiquées à des tiers liés à la
                                  société
                                  TAIWA, par contrat pour l’exécution de tâches sous traitées, sans que
                                  l’autorisation
                                  de l’utilisateur soit nécessaire.
                                  Conformément à la loi N°78-17 du 6 janvier 1978 relative à l’informatique, aux
                                  fichiers et aux libertés, telle que modifiée par la loi N°2004-801 du 6 août 2004
                                  et
                                  par le règlement européen N° 2016/679, l’utilisateur dispose d’un droit d’accès,
                                  de
                                  rectification, d’effacement, et de portabilité des données le concernant, ainsi
                                  que
                                  du
                                  droit de s’opposer au traitement ou de le limiter en s’opposant à la prospection,
                                  pour
                                  motif légitime, droits qu’il peut exercer en s’adressant au responsable de
                                  traitement
                                  à l’adresse postale ou email mentionné ci-dessus, en joignant un justificatif de
                                  son
                                  identité valable.
                                  Il peut exercer son droit directement depuis le site ou en écrivant à la société
                                  TAIWA
                                  par courriel : contact@taiwa.fr
                                  En cas de réclamation, l’utilisateur peut contacter la Commission Nationale de
                                  l’Informatique et des Libertés (CNIL) par l’intermédiaire de son site par le lien
                                  suivant : https://www.cnil.fr/fr/vous-souhaitez-contacter-la-cnil
                                  Par voie postale à l’adresse suivante : 3 place de fontenoy, TSA 80715-75334 PARIS
                                  CEDEX 07
                                  Par téléphone au numéro suivant : 01 53 73 22 22


                                  <h3>Article 24 : Restitution des données</h3>
                                  En cas de cessation de la relation contractuelle, quelle qu’en soit la cause,
                                  l'éditeur du site s’engage à détruire ou à restituer, au choix du Client, à la
                                  première demande de celui-ci formulée par lettre recommandée avec accusé de
                                  réception,
                                  l’ensemble des données lui appartenant sous un format standard lisible sans
                                  difficulté
                                  dans un environnement équivalent ; éventuellement le format précis des données
                                  pourra
                                  être précisé lors des échanges entre le site et le client. Le client collaborera
                                  activement avec le site afin de faciliter la récupération des données. Le site
                                  fera
                                  en
                                  sorte que le client puisse poursuivre l’exploitation des données, sans rupture,
                                  directement ou avec l’assistance d’un autre prestataire.
                                  <h3>Article 25 : Encadrement des conditions</h3>
                                  Si une disposition des présentes conditions générales est jugée illégale, nulle ou
                                  pour toute autre raison inapplicable, alors cette disposition sera réputée
                                  divisible
                                  des conditions générales et n'affectera pas la validité et l'applicabilité des
                                  dispositions restantes. Ces présentes conditions décrivent l’ensemble de l’accord
                                  entre l’utilisateur et le site internet. Elles remplacent tous accords antérieurs
                                  ou
                                  contemporains écrits ou oraux. Les conditions générales ne sont pas cessibles,
                                  transférables ou sous-licenciables par l’utilisateur lui-même. Une version
                                  imprimée
                                  des conditions générales et de tous les avis donnés sous forme électronique pourra
                                  être demandée dans des procédures judiciaires ou administratives en rapport avec
                                  les
                                  conditions générales. Les parties conviennent que toute la correspondance relative
                                  à
                                  ces conditions générales doit être rédigée en langue française.
                                  <h3>Article 26 : Notifications</h3>
                                  Toute notification ou avis concernant les présentes conditions générales, les
                                  mentions
                                  légales ou la charte de données personnelles doit être faite par écrit et doit
                                  être
                                  remis en mains propres, courrier recommandé ou certifié, par Poste ou tout autre
                                  service de messagerie reconnu au niveau national qui permet de suivre
                                  régulièrement
                                  ses forfaits, ou encore par mail aux adresses indiquées dans les mentions légales
                                  du
                                  site, en précisant vos noms, prénoms, coordonnées et objet de l’avis.
                                  <h3>Article 27 : Inexactitudes</h3>
                                  Il peut être possible que se trouvent, dans l’ensemble du site internet et des
                                  services proposés, et dans une mesure restreinte, des inexactitudes ou des
                                  erreurs,
                                  ou
                                  des informations qui soient en désaccord avec les conditions générales, les
                                  mentions
                                  légales ou la charte de données personnelles. En outre, il est possible que des
                                  modifications non autorisées soient faites par des tiers sur le site ou sur des
                                  services annexes (réseaux sociaux…). Nous mettons tout en œuvre pour que ce genre
                                  d’écarts soient corrigés. Dans le cas où une telle situation nous échapperait,
                                  merci
                                  de nous contacter par courrier postal ou par mail aux adresses indiquées dans les
                                  mentions légales du site avec, si possible, une description de l’erreur et
                                  l'emplacement (URL), ainsi que des informations suffisantes pour nous permettre de
                                  vous contacter. Pour les demandes portant sur le droit d'auteur, merci de vous
                                  référer
                                  à la section sur la propriété intellectuelle.
                                  <p>Tous droits réservés - 21 décembre 2023</p>


                                </v-card-text>
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="green darken-1" text @click="dialogCGU = false">Fermer les
                                    CGVU</v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-col>


                          <!-- <v-flex class="py-3">
                              <v-row>
                            <v-col cols="12" md="6" class="d-flex justify-center align-center">
                              <v-card class="mx-auto" outlined @click="selectOffer('Starter')">
                                <v-card-title class="headline blue--text">🚀 Offre Starter</v-card-title>
                                <v-card-text>
                                  <v-list dense>
                                    <v-list-item>
                                      <v-list-item-icon>
                                        <v-icon color="blue">mdi-account</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>Adapté pour une personne travaillant seule en
                                        parcelle</v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-alert dense outlined color="purple">50€ / mois</v-alert>
                                    </v-list-item>
                                  </v-list>
                                </v-card-text>
                                <v-card-actions class="d-flex flex-column align-center">
                                  <v-btn color="primary" block @click.stop="subscribe('Starter')">
                                    <v-icon left>mdi-send-circle</v-icon> Souscrire à
                                    l'offre
                                    Starter</v-btn>
                                  <v-btn text color="blue" @click.stop="viewDetails('Starter')">Voir plus de
                                    détail</v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-col>

                            <v-col cols="12" md="6" class="d-flex justify-center align-center">
                              <v-card class="mx-auto" outlined @click="selectOffer('Pro')">
                                <v-card-title class="headline green--text">✨ Offre Pro</v-card-title>
                                <v-card-text>
                                  <v-list dense>
                                    <v-list-item>
                                      <v-list-item-icon>
                                        <v-icon color="green">mdi-account-group</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>Adapté pour un travail en équipe sur plusieurs
                                        parcelles</v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                      <v-alert dense outlined color="purple">100€ / mois</v-alert>
                                    </v-list-item>
                                  </v-list>
                                </v-card-text>
                                <v-card-actions class="d-flex flex-column align-center">
                                  <v-btn color="primary" block @click.stop="subscribe('Starter')">
                                    <v-icon left>mdi-send-circle</v-icon> Souscrire à
                                    l'offre
                                    Pro</v-btn>
                                  <v-btn text color="blue" @click.stop="viewDetails('Pro')">Voir plus de
                                    détail</v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-col>
                      </v-row>
                    <v-radio-group v-model="premium" row
                                  :rules="[v => !!v || 'Une offre doit être choisie']">
                                  <v-radio label="Je travaille seul" value="solo" class="pa-2"></v-radio>
                                  <v-radio label="Je travaille en équipe" value="team" class="pa-2"></v-radio>
                                </v-radio-group> -->
                          <!-- </v-flex>  -->
                        </v-form>
                      </v-col>
                    </v-row>
                  </v-container>

                </v-card-text>
                <v-row align="center" justify="center" class="ma-2">

                  <v-col cols="12" class="text-center">
                    <v-btn large class="ml-8 white--text" :disabled="!valid" @click="register" :loading="loading"
                      :color="valid ? 'pink' : 'primary'">
                      <v-icon>{{ valid ? 'mdi-lock-open-check' : "mdi-lock" }} </v-icon>Créer son compte (étape 1/2)
                    </v-btn>
                  </v-col>

                  <v-row id="v-step-3" v-if="!ifFilled" align="center" justify="center" class="mt-8 mb-2">
                    <v-icon size="50" color="primary">mdi-dots-horizontal-circle</v-icon>

                    <v-col cols="12" class="text-center">
                      <v-btn @click="Oauth" class="ml-1 pa-4" rounded color="blue" dark>
                        <v-icon>mdi-google</v-icon> Création avec Google
                      </v-btn>
                    </v-col>
                    <v-col cols="12" class="text-center">
                      <v-btn @click="OauthApple" class="ml-1 pa-4" rounded color="black" dark>
                        <v-icon>mdi-apple</v-icon> Création avec Apple
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-tour name="myTour" :steps="steps" :options="labels" button-skip-label="Passer"
                    button-next-label="Suivant" button-prev-label="Précédent"></v-tour>

                </v-row>

              </v-stepper-content>

            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

  </div>
</template>


<style>
.bg {
  transition: background-image 1s;
}

.overlay {
  transition: opacity 1s;
}

.fade {
  opacity: 0;
}
</style>

<script>
import { initializeApp } from 'firebase/app';
import 'firebase/compat/auth';
import xhr from '../plugins/axios'
import { debounce } from 'lodash';
import { mapActions } from 'vuex';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyAI1ZOHrb1zKj-f1Iu6r6BTG87t5qbfUeE",
  authDomain: "cartovia-3a989.firebaseapp.com",
  projectId: "cartovia-3a989",
  storageBucket: "cartovia-3a989.appspot.com",
  messagingSenderId: "1080134626602",
  appId: "1:1080134626602:web:13828952dd2badd70b4253"
};
import { getAuth, GoogleAuthProvider, OAuthProvider, createUserWithEmailAndPassword, signInWithPopup } from "firebase/auth";

const firebase = initializeApp(firebaseConfig);

export default {
  name: "Register",
  data() {
    return {
      premium: null,
      annualStarter: false,
      annualPro: false,
      checkboxNewsletter: false,
      dialogCGU: false,
      dialogSubscribed: false,
      e1: 1,
      showPassword: false,
      presenteUser: false,
      images: [
        "https://images.unsplash.com/photo-1597337726353-26512fbe80c6?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fHZpZ25lfGVufDB8fDB8fHww",
        // "https://images.unsplash.com/photo-1495107334309-fcf20504a5ab?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8ZmllbGR8ZW58MHx8MHx8fDA%3D",
        "https://images.unsplash.com/photo-1555689241-72b068a2e0d3?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fHllbGxvdyUyMGdyYXNzfGVufDB8fDB8fHww",
        "https://images.unsplash.com/photo-1504279577054-acfeccf8fc52?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8dmlnbmV8ZW58MHx8MHx8fDA%3D",
        // "https://images.unsplash.com/photo-1434725039720-aaad6dd32dfe?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZmllbGQlMjBiYWNrZ3JvdW5kfGVufDB8fDB8fHww",
        "https://images.unsplash.com/photo-1500382017468-9049fed747ef?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8ZmllbGR8ZW58MHx8MHx8fDA%3D"
      ],
      existEmail: false,
      currentIndex: 0,

      tourOptions: {
        labels: {
          buttonSkip: 'Passer',
          buttonNext: 'Suivant',
          buttonPrevious: 'Précédent',
        },
      },
      steps: [
        {
          target: '#v-step-1',  // We're using document.querySelector() under the hood
          header: {
            title: 'Obligatoire',
          },
          params: {
            placement: 'top'
          },
          content: 'Quel profil êtes-vous ?',
        },
        // {
        //   target: '#v-step-2',  // We're using document.querySelector() under the hood
        //   header: {
        //     title: 'Obligatoire',
        //   },
        //   params: {
        //     placement: 'top'
        //   },
        //   content: 'Remplissez votre nom et prénom ainsi que e-mail et mot de passe',
        // },
        {
          target: '#v-step-2',
          header: {
            title: 'Facultatif',
          },
          content: 'Vous pouvez aussi remplir votre Entreprise et Numéro de téléphone',
          params: {
            placement: 'bottom'
          }
        },
        {
          target: '#v-step-4',
          header: {
            title: 'Via les réseaux sociaux',
          },
          content: 'Vous pouvez aussi créer votre compte via Google ou Apple',
          params: {
            placement: 'top'
          }
        }
      ],
      snack: 'Mauvais e-mail ou mot de passe. Veuillez réessayer.',
      snackbar: false,
      showNameField: false,
      showTelField: false,
      showEmailField: false,
      customTheme: {
        themes: {
          light: {
            primary: '#FFA500',
            secondary: '#236370',
            accent: '#660E07',
            error: '#EF7D00',
          },
        },
      },
      loading: false,
      interval: null,
      valid: true,
      password: "",
      repassword: "",
      nomRules: [
        (v) => !!v || "Le nom est obligatoire",
        (v) =>
          (v && v.length >= 2) ||
          "Le nom doit faire au moins 2 caractères",
        v => (v && v.length <= 50) || 'Le nom ne doit pas dépasser 50 caractères',
      ],
      surnameRules: [
        (v) => !!v || "Le prénom est obligatoire",
        (v) =>
          (v && v.length >= 2) ||
          "Le prénom doit faire au moins 2 caractères",
        v => (v && v.length <= 50) || 'Le prénom ne doit pas dépasser 50 caractères',
      ],
      nomCompagnyRules: [
        (v) =>
          v.length == 0 || (v && v.length > 2) ||
          "Le nom d'entreprise doit faire au moins 2 caractères",
        v => v.length == 0 || (v && v.length <= 100) || "Le nom d'entreprise ne doit pas dépasser 50 caractères",
      ],
      nameRules: [
        (v) => !!v || "Mot de passe obligatoire",
        (v) => v && v.length >= 8 || "Le mot de passe doit faire au moins 8 caractères",
        (v) => /[A-Z]/.test(v) || "Le mot de passe doit contenir au moins une majuscule",
        (v) => /[0-9]/.test(v) || "Le mot de passe doit contenir au moins un chiffre",
        (v) => v === this.password || 'Les mots de passe ne correspondent pas', // Vérifie si les mots de passe correspondent
      ],
      telRules: [
        (v) =>
          (v.length == 0 || /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/.test(v)) ||
          "Le téléphone doit être valide",
      ],
      emailRules: [
        (v) => !!v || "L'e-mail obligatoire",
        (v) => /.+@.+\..+/.test(v) || "L'e-mail doit être valide",
      ],
    };
  },
  created() {
    if (this.name.length && this.surname.length) {
      this.presenteUser = true
    }
  },
  mounted() {
    this.interval = setInterval(this.changeBackground, 5000);
    if (this.$route.query.usePlt) {
      this.dialogSubscribed = true
    } else {
      setTimeout(() => {
        this.$tours['myTour'].start()
      }, 2000)
    }

  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {
    name: {
      get() {
        return this.$store.getters.getName;
      },
      set(value) {
        this.updateName(value);
      }
    },
    type: {
      get() {
        return this.$store.getters.type;
      },
      set(value) {
        this.updateType(value);
      }
    },
    nameCompagny: {
      get() {
        return this.$store.getters.getNameCompagny;
      },
      set(value) {
        this.updateNameCompagny(value);
      }
    },
    surname: {
      get() {
        return this.$store.getters.getSurname;
      },
      set(value) {
        this.updateSurname(value);
      }
    },
    email: {
      get() {
        return this.$store.getters.getEmail;
      },
      set(value) {
        this.updateEmail(value);
      }
    },
    tel: {
      get() {
        return this.$store.getters.getTel;
      },
      set(value) {
        this.updateTel(value);
      }
    },
    currentImage() {
      return this.images[this.currentIndex];
    },
    ifFilled() {
      return this.email.length > 0 || this.name.length > 0 || this.nameCompagny.length > 0 || this.tel.length > 0 || this.password.length > 0 || this.repassword.length > 0;
    },
    labels() {
      return {
        labels: {
          buttonSkip: 'Passer',
          buttonNext: 'Suivant',
          buttonPrevious: 'Précédent',
          buttonStop: 'Finir'
        },
      }
    }
  },
  methods: {
    ...mapActions(['updateName', 'updateType', 'updateSurname', 'updateEmail', 'updateNameCompagny', 'updateTel', 'setVerified']),
    selectOffer(offerType) {
      console.log(`Offer ${offerType} selected.`);
    },
    subscribe(offerType) {
      console.log(`Subscribing to ${offerType}, annual: ${offerType === 'Starter' ? this.annualStarter : this.annualPro}`);
      // Add subscription logic here
    },
    viewDetails(offerType) {
      // Navigate to detailed page or open modal with more details
      console.log(`View details for ${offerType}`);
    },
    async validateSubscribe() {

      let data = localStorage.getItem("user", null);
      if (data) {
        let user = JSON.parse(data)
        console.log("user", user);
        let res = await xhr.post("/validate-subscribe", {
          email: user.email,
          token: user.name,
          uid: user.uid,
          name: this.name,
          tel: this.tel
        })
        if (!res.data || res.data === null || res.data.success === false) {
          this.snackbar = true;
          this.loading = false;
        } else if (res.data) {
          this.$router.push('/import?first=true')
        }
      }

    },
    setFirebaseUid(uid) {
      this.$store.dispatch('updateUid', uid);
    },
    formatPhoneNumber() {
      // Retirez tout ce qui n'est pas un chiffre
      let numbers = this.tel.replace(/\D/g, '');
      // Réinitialisez la valeur formatée
      let formatted = '';
      // Découpez et ajoutez des espaces selon le format français standard
      // Exemple: 06 01 02 03 04
      numbers = numbers.substring(0, 10); // Assurez-vous de ne pas dépasser 10 chiffres

      for (let i = 0; i < numbers.length; i++) {
        if (i !== 0 && i % 2 === 0) formatted += ' '; // Ajoute un espace tous les 2 chiffres
        formatted += numbers[i];
      }

      // Mettez à jour le modèle avec la valeur formatée
      this.tel = formatted;

      this.updateTel(this.tel);
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    verifyEmail: debounce(async function () {
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

      if (this.email.length > 2 && regex.test(this.email)) {
        this.existEmail = false
        this.loading = true
        let res = await xhr.post("/check-email", { email: this.email.trim() })

        if (res.data == true) {
          this.existEmail = true
        } else {
          this.existEmail = false
          this.updateEmail(this.email);
        }
        this.loading = false

      }

    }, 500),
    changeBackground() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    },

    async Oauth() {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      try {
        const result = await signInWithPopup(auth, provider);



        if (result && result.user) {
          const user = result.user;
          const userId = user.uid;
          let res = await xhr.post("/register-oauth", { email: user.email, oauth: "google", uid: userId })
          if (!res.data || res.data === null || res.data.success === false) {
            this.snackbar = true;
            this.loading = false;
          } else if (res.data) {
            this.loading = false;
            console.log("user created", user);
            localStorage.setItem("id", user.uid);
            localStorage.setItem("email", user.email);
            localStorage.setItem("url", user.photoURL);
            localStorage.setItem("name", user.displayName);
            localStorage.setItem("user", JSON.stringify(user));
            window.location = "/register?usePlt=true";

          }


        }


      } catch (error) {
        console.log("error", error);
        this.snackbar = true
        this.snack = "Un problème est survenu dans l'inscription"
      }


    },
    async OauthApple() {
      const auth = getAuth();
      const provider = new OAuthProvider('apple.com');

      provider.addScope('email');
      provider.addScope('name');
      provider.setCustomParameters({
        // Localize the Apple authentication screen in French.
        locale: 'fr'
      });

      signInWithPopup(auth, provider)
        .then(async (result) => {
          // The signed-in user info.

          if (result.user) {
            const user = result.user;
            const userId = user.uid;
            let res = await xhr.post("/register-oauth", { email: user.email, oauth: "apple", uid: userId })
            if (!res.data || res.data === null || res.data.success === false) {
              this.snackbar = true;
              this.loading = false;
            } else if (res.data) {
              this.loading = false;
              localStorage.setItem("id", user.uid);
              localStorage.setItem("email", user.email);
              localStorage.setItem("user", JSON.stringify(user));

              window.location = "/register?usePlt=true";
            }
          }


          // Apple credential
          // const credential = OAuthProvider.credentialFromResult(result);
          // const accessToken = credential.accessToken;
          // const idToken = credential.idToken;


          // console.log(result, "result")
          // IdP data available using getAdditionalUserInfo(result)
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData.email;
          // The credential that was used.
          const credential = OAuthProvider.credentialFromError(error);

          console.log(error, "error")
          // ...
        });

    },
    async register() {
      this.loading = true;

      try {
        let res = await xhr.post("/register", { type: this.type, name: this.name, surname: this.surname, nameCompagny: this.nameCompagny, telCompagny: this.tel, email: this.email, password: this.password })
        if (!res.data || res.data === null || res.data.success === false) {
          this.snackbar = true;
          this.loading = false;
        } else if (res.data) {
          this.loading = false;
          // localStorage.setItem("id", res.data._id);
          // localStorage.setItem("type", this.type);
          // localStorage.setItem("email", res.data.email);
          // localStorage.setItem("user", JSON.stringify(res.data));
          this.$router.push('/confirm-account')
          // this.$router.push('/register-premium')
          // this.$router.push('/import?first=true')
          // window.location = "/import";
        }
      } catch (error) {
        console.log("error", error);
        this.snackbar = true
        this.snack = "Un problème est survenu dans l'inscription"
      }

    },
  },

  watch: {
    premium(val) {
      console.log('val', val);
    }
  }
};
</script>

<style lang="scss">
.fade-slide-up-enter-active,
.fade-slide-up-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.fade-slide-up-enter,
.fade-slide-up-leave-to

/* .fade-slide-up-leave-active in <2.1.8 */
  {
  opacity: 0;
  transform: translateY(20px);
}

.price-display {
  font-size: 1.5em;
  /* Augmente la taille de la police */
  font-weight: bold;
  /* Rend le texte en gras */
  color: #4CAF50;
  /* Ajoute un fond légèrement gris pour le faire ressortir */
  padding: 8px;
  /* Ajoute un peu de padding autour du texte */
  border-radius: 4px;
  /* Rondit les coins du fond */
  margin-top: 8px;
  /* Un peu d'espace au-dessus pour la séparation */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Un léger ombrage pour donner de la profondeur */
}
 .video-container {
  overflow: hidden;
 }

 .video-container video {
   position: absolute;
   min-height: 100vh;
    height: 100%;
    min-width: 100%;
   z-index: -1;
   background-size: cover;
 }
</style>